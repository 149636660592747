define("front-end/services/current-user", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Service.extend({
        store: _ember["default"].inject.service("store"),
        session: _ember["default"].inject.service("session"),

        user: null,

        fetch: function fetch() {
            var _this = this;

            if (this.get("user") != null) {
                // console.log("user from cache");
                // return this.get("user");
                return new Promise(function (resolve) {
                    resolve(_this.get("user"));
                });
            }

            var userId = this.get("session.currentUser.uid");
            if (userId === undefined) {
                // console.log("userId not yet defined");
                return new Promise(function (resolve, reject) {
                    reject("Not signed in");
                });
            }

            var promise = this.get('store').findRecord('user', userId, { adapterOptions: { include: ['distributions', 'distributions.charity.id'] } })["catch"](function () {
                return null;

                // _this.set("user", promise);
                // _this._setCurrentUser(promise);

                // return promise;
            });

            // console.log("user from store");
            // _this.set("user", promise);
            _this._setCurrentUser(promise);

            return promise;
        },

        _setCurrentUser: function _setCurrentUser(promise) {
            var _this = this;

            promise.then(function (user) {
                _this.set('user', user);
            });
        },

        init: function init() {
            this.fetch();
        }
    });
});