define('front-end/router', ['exports', 'ember', 'front-end/config/environment'], function (exports, _ember, _frontEndConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _frontEndConfigEnvironment['default'].locationType,
    rootURL: _frontEndConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('sign-in');
    this.route('sign-up');
    this.route('app', function () {
      // this.route('app', { path: '/' });
      this.route('list');
      this.route('settings');
      this.route('sepa');
      this.route('info');
      this.route('landing');
    });
  });

  exports['default'] = Router;
});