define("front-end/helpers/get-sector-image", ["exports", "ember"], function (exports, _ember) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

    var Helper = _ember["default"].Helper;
    exports["default"] = Helper.extend({
        mapping: {
            "Blinden/slechtzienden en/of doven/slechthorenden": "blinddeaf.svg",
            "Gehandicaptenzorg": "disabled.svg",
            "Volksgezondheid": "health.svg",
            "Ontwikkelingswerk": "aid.svg",
            "Slachtofferhulp": "victim.svg",
            "Vluchtelingenhulp": "refugee.svg",
            "Dierenbelangen": "animal.svg",
            "Milieubelangen": "environment.svg",
            "Natuurbehoud": "nature.svg",
            "Kerk en levensbeschouwing": "philosophy.svg",
            "Kunst en cultuur": "art.svg",
            "Maatschappelijke/sociale doelen": "social.svg",
            "Mensenrechten": "humanrights.svg",
            "Onderwijs en onderzoek": "education.svg",
            "Sport en recreatie": "sports.svg"
        },

        compute: function compute(_ref) {
            var _ref2 = _slicedToArray(_ref, 1);

            var sector = _ref2[0];

            return "assets/images/" + this.get('mapping')[sector];
        }
    });
});