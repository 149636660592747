define('front-end/components/charity-card', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        distribution: null,

        weight1: 1,
        weight2: 2 / 3,
        weight3: 1 / 3,

        color: "",

        actions: {
            setWeight: function setWeight(weight) {
                this.get('distribution').set('weight', weight);
                return true;
            }
        },

        loadDistribution: (function () {
            var _this = this;
            this.get('distribution').get('charity').then(function (charity) {});
        }).on('init')
    });
});