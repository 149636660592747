define('front-end/routes/app/settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      // Make sure list can only be shown from index
      if (!this.controllerFor('app.index').get('loaded')) {
        this.transitionTo('app');
      }
    }

  });
});