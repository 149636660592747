define("front-end/components/charity-card2", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.get("distribution") != null) {
        (function () {

          var _this = _this2;
          var $slider = _ember["default"].$("input[type=range]", _this2.get('element'));

          if (_this.get("distribution.charity.colors.vibrant") !== undefined) {
            $slider.css("background-color", _this.get("distribution.charity.colors.vibrant"));
          }

          _this2.get("distribution").addObserver("charity.colors.vibrant", function () {

            if (_this.get("distribution.charity.colors.vibrant") !== undefined) {
              $slider.css("background-color", _this.get("distribution.charity.colors.vibrant"));
            }
          });
        })();
      }
    },

    actions: {
      remove: function remove(distribution) {
        distribution.set("removed", true);
        distribution.set("weight", 0);
        console.log(distribution);
      }
    }

  });
});