define('front-end/routes/app/list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        userService: _ember['default'].inject.service('current-user'),

        model: function model() {
            return _ember['default'].RSVP.hash({
                charities: this.store.query('charity', {
                    orderBy: 'active',
                    startAt: true
                }),
                user: this.get('userService').fetch()
            });
        },

        beforeModel: function beforeModel() {
            //Make sure list can only be shown from index
            if (!this.controllerFor('app.index').get('loaded')) {
                this.transitionTo('app');
            }
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.afterModel();
        }

    });
});