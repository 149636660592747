define('front-end/controllers/sign-up', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    firebaseApp: _ember['default'].inject.service(),

    queryParams: ['code', 'email', 'name'],
    code: null,
    validCode: false,

    error: "",
    email: "",
    name: "",
    password: "",

    actions: {
      checkCode: function checkCode() {
        var _this2 = this;

        // let promise = this.get('store').createRecord('code', {
        //   id: "ABCD",
        //   used: false,
        // });
        // promise.save();

        this.set("checking", true);
        this.get('store').findRecord('code', this.get("code")).then(function () {
          _this2.set("validCode", true);
          _this2.set("error", "");
          _this2.set("checking", false);
        }, function () {
          _this2.set("error", "Ongeldige code");
          _this2.set("checking", false);
        });
      },

      signUp: function signUp() {
        var _this3 = this;

        var _this = this;

        console.log("sign up...");
        this.set("error", "");
        this.set("checking", true);

        var auth = this.get('firebaseApp').auth();
        auth.createUserWithEmailAndPassword(this.get('email'), this.get('password')).then(function (result) {
          console.log(result);
          var user = _this.get('store').createRecord('user', {
            id: result.uid,
            uid: result.uid,
            name: _this3.get('name'),
            email: _this3.get('email')
          });
          user.save();
          window.location.assign('/app/landing');
        }, function (error) {
          console.log(error);
          _this3.set("checking", false);
          _this3.set("error", "Aanmelden mislukt (" + error + ")");
        });
      },

      signUpFB: function signUpFB() {
        var _this4 = this;

        var _this = this;
        this.get('session').open('firebase', {
          provider: "Facebook"
        }).then(function (result) {
          console.log(result);
          var user = _this.get('store').createRecord('user', {
            id: result.uid,
            uid: result.uid,
            name: result.currentUser.displayName,
            email: result.currentUser.email
          });
          user.save();
          window.location.reload(true);
        }, function (error) {
          console.log(error);
          _this4.set("checking", false);
          _this4.set("error", "Aanmelden via Facebook mislukt...");
        });
      }
    }

  });
});