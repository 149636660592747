define('front-end/routes/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            var session = this.get('session').fetch()['catch'](function () {});
            // if (this.get("session.currentUser.uid") === undefined) {
            //   this.transitionTo('sign-in');
            // }
            return session;
            //Get user here?
        }
    });
});