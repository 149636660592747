define('front-end/components/paper-api', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var typeOf = _ember['default'].typeOf;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var escapeExpression = _ember['default'].Handlebars.Utils.escapeExpression;

  var escape = function escape(text) {
    var result = escapeExpression(text);
    // Convert backtick markup, as escaped by escapeExpression to <code> element.
    result = result.replace(/&#x60;(.*?)&#x60;/g, '<code>$1</code>');
    // Convert ** markup to <em> element.
    result = result.replace(/\*\*(.*?)\*\*/g, '<em>$1</em>');
    // Convert * markup to <strong> element.
    result = result.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

    return result ? htmlSafe(result) : '';
  };

  exports['default'] = Component.extend({
    tagName: '',
    // As statically-declared properties, these are shared amongst all instances
    // of `ember-api`.
    title: 'Usage',
    header: ['Option', 'Type', 'Description'],
    categorySpec: [],
    sort: true,

    // Predefined categories.
    color: ['Theme colors', ['warn', 'boolean', 'Displays the button in the theme\'s warn color.'], ['accent', 'boolean', 'Displays the button in the theme\'s Accent color.']],

    categories: computed('categorySpec', function () {
      var _this = this;

      var categories = [];
      this.get('categorySpec').forEach(function (category) {
        category = typeOf(category) === 'string' ? _this.get(category) : category;
        categories.push(_this.getEscapedCategory(category));
      });
      return categories;
    }),

    getEscapedCategory: function getEscapedCategory(category) {
      /* jshint -W014 */
      var shouldSort = this.get('sort');

      if (shouldSort) {
        category = category.slice().sort(function (a, b) {
          return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
        });
      }

      return category.map(function (row) {
        return typeOf(row) === 'array' ? row.map(function (cell) {
          return escape(cell);
        }) : escape(row);
      });
    }

  }).reopenClass({
    positionalParams: 'categorySpec'
  });
});