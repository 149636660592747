define('front-end/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    email: "",
    invitationConfirmationDialogVisible: false,
    currentUser: _ember['default'].inject.service('current-user'),

    actions: {
      signIn: function signIn() {
        var request = this.get('store').createRecord('invitationrequest', {
          email: this.get('email')
        });
        request.save();
        this.set("email", "");
        this.set("invitationConfirmationDialogVisible", true);
      },
      closeInvitationDialog: function closeInvitationDialog() {
        this.set("invitationConfirmationDialogVisible", false);
      }
    }
  });
});