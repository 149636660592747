define('front-end/components/sub-toolbar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        subtoolbar: _ember['default'].inject.service('sub-toolbar'),
        visible: (function () {
            return this.get('subtoolbar.visible');
        }).property('subtoolbar.visible'),

        messages: (function () {
            return this.get('subtoolbar.messages');
        }).property('subtoolbar.messages'),

        actions: {
            click: function click() {
                this.get('subtoolbar').buttonClick();
            },
            reset: function reset() {
                window.location.reload();
            }
        }
    });
});