define('front-end/components/custom-button', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      targetButton: function targetButton() {
        alert('You pressed a target button. -from component');
      }
    }
  });

  // END-SNIPPET
});
// BEGIN-SNIPPET buttons.component
// app/components/custom-button.js