define("front-end/components/feedback-widget", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    currentUser: _ember["default"].inject.service("current-user"),
    store: _ember["default"].inject.service(),
    dialogIsVisible: false,
    comment: "",
    email: "",
    error: "",
    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: function validate(inputValue) {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(inputValue);
      }
    }],
    actions: {
      showDialog: function showDialog() {
        this.set("dialogIsVisible", true);
      },
      closeDialog: function closeDialog() {
        this.set("dialogIsVisible", false);
      },
      send: function send() {
        var feedback = undefined;

        if (this.get("comment") === "") {
          this.set("error", "Voer een bericht in.");
          return;
        }

        if (this.get("currentUser.user") == null) {
          if (this.get("email") === "") {
            this.set("error", "Voer een e-mail adres in.");
            return;
          }
          feedback = this.get('store').createRecord('feedback', {
            message: this.get("comment"),
            user: null,
            email: this.get("email")
          });
        } else {
          feedback = this.get('store').createRecord('feedback', {
            message: this.get("comment"),
            user: this.get("currentUser.user"),
            email: this.get("currentUser.user.email")
          });
        }
        feedback.save();
        this.set("comment", "");
        this.set("email", "");
        this.set("error", "");
        this.set("dialogIsVisible", false);
      }
    }
  });
});