define('front-end/components/graceful-image', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'img',
        attributeBindings: ['src', 'errorSrc'],

        didInsertElement: function didInsertElement() {
            if (this.get('src') === "" || this.get('src') === null) {
                this.set('src', this.get('errorSrc'));
            }
            this.$().on('error', (function () {
                this.set('src', this.get('errorSrc'));
            }).bind(this));
        },

        willDestroyElement: function willDestroyElement() {
            this.$().off();
        }
    });
});