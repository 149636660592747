define('front-end/helpers/transition-to', ['exports', 'ember-transition-helper/helpers/transition-to'], function (exports, _emberTransitionHelperHelpersTransitionTo) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberTransitionHelperHelpersTransitionTo['default'];
    }
  });
  Object.defineProperty(exports, 'transitionTo', {
    enumerable: true,
    get: function get() {
      return _emberTransitionHelperHelpersTransitionTo.transitionTo;
    }
  });
});