define("front-end/controllers/sign-in", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({

    error: "",
    email: "",
    password: "",

    actions: {
      signIn: function signIn() {
        var _this = this;

        console.log("sign in...");
        this.set("error", "");
        this.get('session').open('firebase', {
          provider: "password",
          email: this.get('email') || '',
          password: this.get('password') || ''
        }).then(function () {
          window.location.reload(true);
        }, function () {
          _this.set("error", "Inloggen mislukt...");
        });
      },
      signInFB: function signInFB() {
        this.get('session').open('firebase', {
          provider: "Facebook"
        }).then(function () {
          window.location.reload(true);
        });
      }
    }
  });
});