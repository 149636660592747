define('front-end/services/encryption', ['exports', 'ember', 'npm:jsencrypt'], function (exports, _ember, _npmJsencrypt) {
    exports['default'] = _ember['default'].Service.extend({

        pubKey: '-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDZ+Cuq746c/LnsL1xrpbTaMELh\n84O3a+1cry0UKWIg144/W8FeClIHl+ZkHG3NW+ZGJbY/hoMA2jaNosTP0Ch7v+vr\nxvlEsZ6n3pnJt9hupAC4vHE/HoeXvn1x+Y9NSs9QTYE2W78rAcmU1VcMkFRQceig\nZl/GUPBI2uSB0+gBaQIDAQAB\n-----END PUBLIC KEY-----',

        encrypt: function encrypt(data) {

            var encrypt = new _npmJsencrypt['default'].JSEncrypt();
            encrypt.setPublicKey(this.get('pubKey'));
            console.log(encrypt);

            return encrypt.encrypt(data);
        }
    });
});