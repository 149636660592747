define('front-end/routes/app/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    userService: _ember['default'].inject.service('current-user'),

    beforeModel: function beforeModel() {
      if (this.get("session.currentUser.uid") === undefined) {
        this.transitionTo('index');
      }
    },
    model: function model() {
      return this.get('userService').fetch();
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.afterModel();
    }
  });
});