define('front-end/components/card-illustration', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        charity: null,

        r1: -10,
        r2: 20,
        r3: -30,
        r4: 10,
        r5: -70,
        r6: -45,

        loadRandomRotations: _ember['default'].observer('charity', function () {
            this.set('r1', this.get('r1') + this.getRandom(1, 30));
            this.set('r2', this.get('r2') + this.getRandom(2, 30));
            this.set('r3', this.get('r3') + this.getRandom(3, 30));
            this.set('r4', this.get('r4') + this.getRandom(-1, 10));
            this.set('r5', this.get('r5') + this.getRandom(-2, 30));
            this.set('r6', this.get('r6') + this.getRandom(-3, 30));
        }),

        getRandom: function getRandom(seed, max) {
            var id = this.get('charity').get('id');
            var num = 0;
            for (var i = 0; i < id.length; i++) {
                num += id.charCodeAt(i);
            }
            num = num % max;
            num += seed * 3;
            return num;
        }
    });
});