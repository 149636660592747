define('front-end/controllers/app', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    userService: _ember['default'].inject.service('current-user'),
    subtoolbar: _ember['default'].inject.service('sub-toolbar'),

    defaultAmounts: [5, 10],
    differentAmount: null,

    user: (function () {
      return this.get('userService').get('user');
    }).property('userService.user'),

    actions: {

      setAmount: function setAmount(amount) {
        this.setAmount(amount);
        this.set('differentAmount', null);
      }
    },

    differentAmountListener: _ember['default'].observer('differentAmount', function () {
      if (this.get('differentAmount') != null) {
        this.setAmount(this.get('differentAmount'));
      }
    }),

    setAmount: function setAmount(amount) {
      var _this = this;
      this.get('user').set('amount', amount);
      this.get('subtoolbar').setMessage('Het bedrag is opnieuw ingesteld.').setButtonAction(function () {
        _this.get('user').save();
      }).show();
    }

  });
});