define('front-end/models/feedback', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    user: _emberData['default'].belongsTo('user'),
    message: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});