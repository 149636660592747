define("front-end/controllers/app/sepa", ["exports", "ember"], function (exports, _ember) {
    var $ = _ember["default"].$;
    exports["default"] = _ember["default"].Controller.extend({
        currentUser: _ember["default"].inject.service("current-user"),
        encryption: _ember["default"].inject.service("encryption"),

        banks: [{
            'name': 'Rabobank',
            'logo': 'assets/images/bank_RABO.png',
            'bic': 'RABONL2U'
        }, {
            'name': 'ING',
            'logo': 'assets/images/bank_INGB.png',
            'bic': 'INGBNL2A'
        }],

        actions: {
            goToBank: function goToBank(bic) {

                var token = btoa(JSON.stringify({
                    bic: bic,
                    user: this.get('currentUser').get('user').get('id'),
                    redirect: window.location.href
                }));

                var url = "http://betaal.doneerapp.com/sepa";

                window.location.replace(url + "?token=" + token);
            }
        }
    });
});