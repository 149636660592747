define('front-end/models/distribution', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        user: _emberData['default'].belongsTo('user'),
        charity: _emberData['default'].belongsTo('charity'),
        weight: _emberData['default'].attr("number"),
        amount: _emberData['default'].attr("number"),
        createdAt: _emberData['default'].attr('date', {
            defaultValue: function defaultValue() {
                return new Date();
            }
        })
    }).reopen({
        removed: false
    });
});