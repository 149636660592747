define('front-end/services/sub-toolbar', ['exports', 'ember'], function (exports, _ember) {

    // const {$} = Ember;

    exports['default'] = _ember['default'].Service.extend({
        showButton: false,
        buttonActions: [],
        messages: [],
        visible: false,

        _shouldAddAction: true,

        setMessage: function setMessage(message) {
            if (!this.get('messages').contains(message)) {
                this.get('messages').pushObject(message);
                this.set('_shouldAddAction', true);
            } else {
                this.set('_shouldAddAction', false); // disable action that will be added with this message
            }
            return this;
        },
        setButtonAction: function setButtonAction(buttonAction) {
            if (this.get('_shouldAddAction')) {
                this.set('showButton', true);
                this.get('buttonActions').push(buttonAction);
            }

            return this;
        },
        show: function show() {
            this.set("visible", true);

            this.set('_shouldAddAction', true); // make sure next action will be added

            return this;
        },
        dismiss: function dismiss() {
            this.set("visible", false);
        },

        buttonClick: function buttonClick() {
            var actions = this.get('buttonActions');
            for (var i = 0; i < actions.length; i++) {
                actions[i]();
            }

            this.dismiss();
            this.set('buttonActions', []);
            this.set('messages', []);
        }
    });
});