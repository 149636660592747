define('front-end/models/charity', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    logo: _emberData['default'].attr('string'),
    goal: _emberData['default'].attr('string'),
    color: _emberData['default'].attr('string'),
    colors: _emberData['default'].attr(),
    beneficiaries: _emberData['default'].attr('string'),
    subsector: _emberData['default'].attr("string"),
    website: _emberData['default'].attr('string'),
    activities: _emberData['default'].attr('string')

  }).reopen({
    added: false,
    hidden: false
  });
});