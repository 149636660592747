define('front-end/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    uid: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    distributions: _emberData['default'].hasMany("distribution"),
    amount: _emberData['default'].attr('number'),

    address: _emberData['default'].attr('string'),
    postalCode: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),

    bankDetails: _emberData['default'].attr()
  });
});