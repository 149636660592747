define('front-end/controllers/app/settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    amounts: [5, 10, 20, 50, 100],
    subtoolbar: _ember['default'].inject.service('sub-toolbar'),
    currentUser: _ember['default'].inject.service("current-user"),
    otherAmount: false,

    differentAmountListener2: (function () {
      if (this.get("amounts").indexOf(this.get('currentUser.user.amount')) < 0) {
        this.set('otherAmount', true);
      }
    }).on("init"),

    actions: {
      setAmount: function setAmount(amount) {
        this.set("currentUser.user.amount", amount);
        this.set('otherAmount', false);
      },
      setOtherAmount: function setOtherAmount() {
        this.set('otherAmount', true);
      },
      signOut: function signOut() {
        this.get('session').close();
        window.location.reload(true);
      }
    },

    changeObserver: _ember['default'].observer('currentUser.user.amount', 'currentUser.user.name', 'currentUser.user.address', 'currentUser.user.postalCode', 'currentUser.user.city', function () {
      var _this = this;
      this.get('subtoolbar').setMessage('De gebruikersgegevens zijn aangepast.').setButtonAction(function () {
        _this.save();
      }).show();
    }),

    save: function save() {
      this.get('currentUser').get('user').save();
    }
  });
});