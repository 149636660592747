define('front-end/controllers/app/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    subtoolbar: _ember['default'].inject.service('sub-toolbar'),
    loaded: false,
    dialogIsVisible: false,
    dialogCharity: null,

    showToolbar: function showToolbar() {
      var _this = this;
      this.get('subtoolbar').setMessage('De verdeling is opnieuw ingesteld.').setButtonAction(function () {
        _this.save();
      }).show();
    },

    afterModel: function afterModel() {
      this.set("loaded", true);
      var _this = this;
      if (this.get("model") !== undefined) {
        this.get("model").addObserver("distributions.@each.weight", function () {
          _ember['default'].run.once(this, function () {
            _this.recalculate();
          });
        });
        this.get("model").addObserver("amount", function () {
          _ember['default'].run.once(this, function () {
            _this.recalculate();
          });
        });
      }
    },

    recalculate: function recalculate() {
      var totalWeight = 0;
      var countDistributions = 0;
      this.get('model.distributions').forEach(function (distribution) {
        totalWeight += parseFloat(distribution.get('weight'));
        countDistributions++;
      });
      if (isNaN(totalWeight)) {
        return;
      }

      var donationAmount = this.get('model.amount');
      var totalAmount = 0;
      var _this = this;
      this.get('model.distributions').forEach(function (distribution, i) {
        if (i < countDistributions - 1) {
          var amount = Math.round(donationAmount / totalWeight * distribution.get('weight') * 100) / 100;
          totalAmount += amount;

          if (distribution.get("amount") !== amount) {
            distribution.set('amount', amount);
            _this.showToolbar();
          }
        } else {
          if (distribution.get("amount") !== donationAmount - totalAmount) {
            distribution.set('amount', donationAmount - totalAmount);
            _this.showToolbar();
          }
        }
      });
    },

    save: function save() {
      this.get('model.distributions').forEach(function (distribution) {
        if (distribution.get("removed")) {
          distribution.deleteRecord();
        }
        distribution.save();
      });
      this.get("model").save();
    },

    actions: {
      showDialog: function showDialog(charity) {
        this.set("dialogCharity", charity);
        this.set("dialogIsVisible", true);
      },
      closeDialog: function closeDialog() {
        this.set("dialogIsVisible", false);
      }
    }
  });
});