define("front-end/controllers/app/list", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        currentUser: _ember["default"].inject.service("current-user"),

        user: null,
        addedDistributions: [],

        // addedDistributionKeys: function() {
        //     console.log("dists: ");
        //     console.log(this.get("addedDistributions"));
        //     console.log(this.get("addedDistributions").length);
        //     return [];
        // }.property("addedDistributions.@each"),

        availableTags: [_ember["default"].Object.create({
            tag: "Blinden/slechtzienden en/of doven/slechthorenden",
            active: false,
            name: "Slechtzienden/-horenden"
        }), _ember["default"].Object.create({ tag: "Gehandicaptenzorg", active: false, name: "Gehandicaptenzorg" }), _ember["default"].Object.create({ tag: "Volksgezondheid", active: false, name: "Volksgezondheid" }), _ember["default"].Object.create({ tag: "Ontwikkelingswerk", active: false, name: "Ontwikkelingswerk" }), _ember["default"].Object.create({ tag: "Slachtofferhulp", active: false, name: "Slachtofferhulp" }), _ember["default"].Object.create({ tag: "Vluchtelingenhulp", active: false, name: "Vluchtelingenhulp" }), _ember["default"].Object.create({ tag: "Dierenbelangen", active: false, name: "Dierenbelangen" }), _ember["default"].Object.create({ tag: "Milieubelangen", active: false, name: "Milieubelangen" }), _ember["default"].Object.create({ tag: "Natuurbehoud", active: false, name: "Natuurbehoud" }), _ember["default"].Object.create({ tag: "Kerk en levensbeschouwing", active: false, name: "Kerk en levensbeschouwing" }), _ember["default"].Object.create({ tag: "Kunst en cultuur", active: false, name: "Kunst en cultuur" }), _ember["default"].Object.create({
            tag: "Maatschappelijke/sociale doelen",
            active: false,
            name: "Maatschappelijke/sociale doelen"
        }), _ember["default"].Object.create({ tag: "Mensenrechten", active: false, name: "Mensenrechten" }), _ember["default"].Object.create({ tag: "Onderwijs en onderzoek", active: false, name: "Onderwijs en onderzoek" }), _ember["default"].Object.create({ tag: "Sport en recreatie", active: false, name: "Sport en recreatie" })],
        search: "",
        activeSearch: "",
        dialogIsVisible: false,
        dialogCharity: null,

        activeTags: (function () {
            var tags = [];
            this.get("availableTags").forEach(function (tag) {
                if (tag.get("active")) {
                    tags.push(tag.get("tag"));
                }
            });
            return tags;
        }).property('availableTags.@each.active'),

        filteredCharities: (function () {
            var activeTags = this.get('activeTags');
            var search = typeof this.get('activeSearch') === "string" ? this.get('activeSearch').toLowerCase() : "";
            // let filterFunction = function(charity) {
            //     let beneficiaries = charity.get("subsector") == null ? "" : charity.get("subsector").toLowerCase();
            //     let name = charity.get("name").toLowerCase();
            //     let goal = charity.get("goal").toLowerCase();
            //     if(
            //         (activeTags.length === 0 ||
            //             activeTags.some(function(tag){
            //             return beneficiaries.indexOf(tag.toLowerCase()) >= 0;
            //         })) &&
            //         (
            //             name.indexOf(search) >= 0 ||
            //             goal.indexOf(search) >= 0
            //         )
            //     ) {
            //         return true;
            //     }
            //     return false;
            // };
            // // Perform the filtering with an array function.
            // return this.get("model.charities").filter(filterFunction);

            console.log("filter");
            // let count = 0;
            this.get("model.charities").forEach(function (charity) {
                // count++;
                var beneficiaries = charity.get("subsector") == null ? "" : charity.get("subsector").toLowerCase();
                var name = charity.get("name").toLowerCase();
                var goal = charity.get("goal").toLowerCase();
                if ((activeTags.length === 0 || activeTags.some(function (tag) {
                    return beneficiaries.indexOf(tag.toLowerCase()) >= 0;
                })) && (name.indexOf(search) >= 0 || goal.indexOf(search) >= 0)) {
                    charity.set("hidden", false);
                } else {
                    charity.set("hidden", true);
                }
            });
            // console.log(count);
            return this.get("model.charities");
        }).property('activeTags.@each', "activeSearch"),

        //Parse charities on added or not (distributions)
        parsedCharitiesWatcher: (function () {
            var addedCharityIds = [];
            this.get("model.user.distributions").forEach(function (distribution) {
                addedCharityIds.push(distribution.get("charity.id"));
            });
            this.get("model.charities").forEach(function (charity) {
                if (addedCharityIds.indexOf(charity.get("id")) >= 0) {
                    charity.set("added", true);
                }
            });
        }).observes("model.user.distributions.@each"),

        afterModel: function afterModel() {},

        actions: {
            toggleCategory: function toggleCategory(tag) {
                tag.set("active", !tag.get("active"));
            },
            search: function search() {
                this.set("activeSearch", this.get("search"));
            },
            add: function add(charity) {

                var _this = this;
                this.get("currentUser").fetch().then(function (user) {
                    _this.get('store').createRecord('distribution', {
                        user: user,
                        charity: charity,
                        weight: 1
                    });
                    // distribution.save();
                    // user.save();
                    // _this.get("router").send("index");
                });
                this.set("dialogIsVisible", false);
            },
            showDialog: function showDialog(charity) {
                this.set("dialogCharity", charity);
                this.set("dialogIsVisible", true);
            },
            closeDialog: function closeDialog() {
                this.set("dialogIsVisible", false);
            }
        }

    });
});